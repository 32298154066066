import React from "react";
import { Link as GatsbyLink } from "gatsby";
import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	ImportExample,
	Header,
	Code,
} from "../../../../../src/docs";
import { HeaderSection, ContentSection } from "../../../../../src/docs/sections";
import { Paragraph } from "../paragraph/paragraph";
import { TextContainer } from "../text-container/text-container";
import { Ul } from "../list/list";
import { H1, H2, H3, H4, H5, H6, useHeading } from "./heading";

export const Meta: DocPageMeta = {
	category: "Text",
	title: "Heading",
	notepad: "https://hackmd.io/Q0rT72VmQxKhc1Hf6ZhBMA",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Heading"
			subTitle="Use this component for titles of pages and page sections."
		/>
		<ContentSection>
			<TextContainer article>
				<ImportExample component={[H1, H2, H3, H4, H5, H6]} />
				<InlineMessage variant="warning">
					<Paragraph>
						When you have multiple consecutive typographic components (
						<GatsbyLink to="../Heading">headings</GatsbyLink>,{" "}
						<GatsbyLink to="../Paragraph">paragraphs</GatsbyLink> and{" "}
						<GatsbyLink to="../List">lists</GatsbyLink>), wrap them in the{" "}
						<GatsbyLink to="../Text container">text-container</GatsbyLink> component. The{" "}
						<GatsbyLink to="../Text container">text-container</GatsbyLink> component will
						automatically add the correct spacing between the typographic components.
					</Paragraph>
				</InlineMessage>
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					The heading component supports the visual and content hierarchy of a page. This helps
					users scan a page, get an overview of its content, and navigate it.
				</Paragraph>
				<Paragraph>
					The heading component features a few properties for customizing its appearance. The
					following sections showcase the properties and explain when to use them.
				</Paragraph>
				<Header.H3>Default</Header.H3>
				<Paragraph>This is the default appearance of the six heading levels.</Paragraph>
				<Example fn={defaultExample} />
				<Header.H3>Hook</Header.H3>
				<Paragraph>Headings can also be retrieved from a hook.</Paragraph>
				<Example fn={hookExample} />
				<Header.H3>Heading that looks like an other heading</Header.H3>
				<Paragraph>
					Headings should be nested according to their level and heading levels should never be
					skipped. In other words, a heading of a certain level should always be followed by a
					heading of either the same level or one below (e.g. an h2 should be followed by another h2
					or an h3). This creates a clear visual hierarchy and helps assistive technology users
					understand and navigate a page's content
				</Paragraph>
				<Paragraph>
					However, sometimes you want a heading to look like an other heading, for aesthetic
					reasons. The lookalike property enables you to do this, without sacrificing accessibility.
					So, if you want an H3 component to be an h3 in code, but look like an H5, you just give
					the lookalike property of the H3 component the value "h5". The result can be seen below.
				</Paragraph>
				<Paragraph>
					Be aware, though, that overuse of this feature will result in visual inconsistency in our
					UI. This will make the visual appearance of our headings less meaningful to our sighted
					users.
				</Paragraph>
				<Example fn={lookalikeExample} />
				<Header.H3>Alignment</Header.H3>
				<Paragraph>
					Use this property to center or right align the text in headings. Use it sparingly, since
					it negatively affects readability.
				</Paragraph>
				<Example fn={alignmentExample} />
				<Header.H3>Line-height</Header.H3>
				<Paragraph>
					This property is by default set to multi-line, since headings may wrap across multiple
					lines. In certain cases, it is certain that a heading will fit on a single line and the
					"extra" vertical height might be unwanted layout-wise. To support these cases, the
					property can be set to single-line.
				</Paragraph>
				<Example fn={lineHeightExample} />
				<Header.H3>Tooltip</Header.H3>
				<Paragraph>
					To use a tooltip for a heading you must use the <Code>tooltipText</Code> prop to ensure
					proper accessibility. Do not add the{" "}
					<GatsbyLink to="/lib/components/Overlay/Tooltip">Tooltip component</GatsbyLink> as a child
					element of the Heading component.
				</Paragraph>
				<Paragraph>
					If you need to change the placement of the tooltip you can use the{" "}
					<Code>tooltipPlacement</Code> prop.
				</Paragraph>
				<Example fn={tooltipExample} />
				<Header.H2>Properties</Header.H2>
				<Knobs
					component={H1}
					initialProps={{ children: "Heading" }}
					exampleValues={{
						children: {
							"short heading": "Short heading",
							"long heading": "Sooooooooooooooooooooome long heading",
						},
						alignment: { left: "left", center: "center", right: "right" },
						lineHeight: { "single-line": "single-line", "multi-line": "multi-line" },
						lookalike: { h1: "h1", h2: "h2", h3: "h3", h4: "h4", h5: "h5", h6: "h6" },
					}}
				/>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Ul
						items={[
							"Use for titles of pages and page sections. For instance, if a card contains multiple charts, then label each of them with an H3 heading (the page will automatically have an H1 and the card an H2)",
							"Use to support the visual and content hierarchy of the page. This helps users scan a page, get an overview of its content, and navigate it.",
							"Place at the top of the page or page section they belong to.",
							<>
								When you have multiple consecutive typographic components (headings,{" "}
								<GatsbyLink to="../Paragraph">paragraphs</GatsbyLink> and{" "}
								<GatsbyLink to="../List">lists</GatsbyLink>, wrap them in the{" "}
								<GatsbyLink to="../Text container">text container</GatsbyLink> component. The
								component will automatically add the correct spacing between the typographic
								components.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Paragraph>
						You want to style some text that is not the title of a page or page section.
					</Paragraph>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Paragraph>
						Headings should be organized hierarchically, without skipping levels. Properly nested
						headings greatly help users of assistive technologies understand and navigate the page's
						content.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul
						items={[
							"Be descriptive. Headings should clearly summarize what users can find on the page or page section.",
							"Resist the temptation to come up with “creative” or mysterious names for things - keep in mind that users are mostly just trying to get work done.",
							"Keep to a single sentence.",
							"Avoid punctuation such as periods, commas, or semicolons.",
							"Write in sentence case unless it’s a product name.",
							<>
								For the{" "}
								<a href="https://my2.siteimprove.com/Styleguide/components/learn-info-box">
									learn component
								</a>
								, use unique, verb-led headings to grab users' attention.
							</>,
						]}
					/>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const defaultExample = () => (
	<>
		<H1>Heading 1</H1>
		<br />
		<H2>Heading 2</H2>
		<br />
		<H3>Heading 3</H3>
		<br />
		<H4>Heading 4</H4>
		<br />
		<H5>Heading 5</H5>
		<br />
		<H6>Heading 6</H6>
	</>
);

// eslint-disable-next-line
const hookExample = () => useHeading("h1", { children: "Heading from hook" });

const lookalikeExample = () => <H1 lookalike="h5">Heading 1, looks like heading 5</H1>;

const alignmentExample = () => (
	<>
		<H1 alignment="left">This heading is left aligned</H1>
		<br />
		<H1 alignment="center">This heading is center aligned</H1>
		<br />
		<H1 alignment="right">This heading is right aligned</H1>
	</>
);

const lineHeightExample = () => (
	<>
		<H2>
			Headings should never be this long, but this example is meant to illustrate how a line height
			that is larger than the font size prevents the lines from sticking together.
		</H2>
		<br />
		<H2 lineHeight="single-line">
			As you can see, a line height that is equal to the font size results in lines that stick
			together, which negatively impacts the reading experience.
		</H2>
	</>
);

const tooltipExample = () => (
	<H1 tooltipText="Tooltip text for a heading" tooltipPlacement="bottom">
		Heading 1
	</H1>
);
